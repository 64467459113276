import React, { useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import AppLinks from '../../app_links/app_links'
import Typography from '../typography'
import Input from '../../ui/input'
import { GreenButton } from '../../ui/button'
import * as styles from './hero.module.css'
import Container from '../container'
import { useInterval } from '../../../utilities.js/utils'
// set position absolute
// force it to slide on specific path
const { Paragraph } = Typography
const isBrowser = typeof window !== 'undefined'

const POLLDELAY = 20000; // 20 s

export default ({ data, assets }) => {
  const [messageVisible, setMessageVisible] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('') // should be of type string

  const [disable, setDisable] = useState(true);
  const [delay, setDelay] = useState(null);

  useInterval(() => {
    setDelay(null);
    setDisable(!disable);
  }, delay)

  const validateInput = (value) => {
    if (isBrowser) {
      let valSplit = value.split(' ').join('')
      if (valSplit.length == 8) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    }
  }

  const sendEvent = (event) => {
    event.preventDefault();

    const valSplit = inputValue.split(' ').join('')
    const fullNumber = '+614' + valSplit
    window.analytics.track('website_signup_sms_referal', {
      recipient: fullNumber,
    })
    setSuccessMessage(true);
    setMessageVisible(true);

    // disable btn until after such time
    setDelay(POLLDELAY);
    setDisable(true);
    
  }

  return (
    <div>
      <Container className={styles.heroContainer}>
        <div className={styles.heroTextContainer}>
          <div className={styles.tagContainer}>
            <h1 className={styles.tag}>{data.topBubbleText}</h1>
          </div>
          <h1 className={styles.title}>{data.title}</h1>
          <Paragraph className={styles.subtitle}>{data.subtitle}</Paragraph>
          {/* Phone number form, temporarily hidden because this feature is not working at the moment. */}
          {/*
          <div className={styles.formTitle}>
            <span className={styles.formTitleText}>{data.formTitle}</span>
          </div>
          {data.fieldPlaceholder != null && (
            <form
              method="post"
              name="mc-embedded-subscribe-form"
              onSubmit={sendEvent}
            >
              <div className={styles.formContainer}>
                <div className={styles.mobileInputContainer}>
                  <Paragraph className={styles.mobilePreFill}>
                    {data.fieldPlaceholder}&nbsp;
                  </Paragraph>
                  <Input
                    key="phone"
                    // Do not change id, used for focus
                    id="phone-input"
                    maxLength={10}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    value={inputValue}
                    onChange={(e) => {
                      setMessageVisible(false);
                      validateInput(e.target.value)
                      
                      let val = e.target.value;
                      const currVal = inputValue
                      const currValSpace = currVal.split('')
                      const valSpace = val.split('')
                      // Case deleting space just created
                      if (
                        currValSpace[currValSpace.length - 1] == ' ' &&
                        currVal.length - val.length === 1
                      ) {
                        setInputValue(val)
                        return
                      }
                      // Backspace handling
                      if (valSpace[valSpace.length - 1] == ' ') {
                        val = valSpace.slice(0, -1).join('')
                        setInputValue(val)
                        return
                      }
                      setInputValue(val)
                      if (val.length === 2 || val.length === 6) {
                        setInputValue(val + ' ')
                      }
                      // Recreate space if was deleted
                      if (val.length === 3 || val.length === 7) {
                        setInputValue(
                          val.slice(0, -1) + ' ' + valSpace[valSpace.length - 1]
                        )
                      }
                    }}
                    // type="phone"
                    // name="EMAIL"
                    placeholder="XX-XXX-XXX"
                    className={styles.mobileInput}
                    required
                    size={1}
                  />
                </div>
                <GreenButton
                  name="subscribe"
                  type="submit"
                  disabled={disable}
                  leftArrow={disable ? successMessage && messageVisible ? false : true : true}
                  className={styles.sendBtn}
                >
                  {disable ? successMessage && messageVisible ? '👍' : data.fieldButton.label : data.fieldButton.label}
                </GreenButton>
              </div>
            </form>
          )}
          */} 
          <Paragraph
            className={
              `${styles.phoneMessage} ` +
              (successMessage
                ? `${styles.phoneSuccessMessage}`
                : `${styles.phoneErrorMessage}`)
            }
          >
            &nbsp;
            {messageVisible && successMessage && data.successMessage}
            {messageVisible && !successMessage && data.errorMessage}
          </Paragraph>
          {data.fieldPlaceholder != null && (
            <div className={styles.appLinks}>
              <AppLinks assets={assets} browserLink={true} isBlack={false} />
            </div>
          )}
        </div>
        <div className={styles.heroImageContainer}>
          <GatsbyImage
            alt={data.rightImage.title}
            className={styles.image}
            image={data.rightImage.gatsbyImageData}
          />
        </div>
      </Container>
    </div>
  )
}
