import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import EmployeeAppSection from '../components/employee_app_section/employee_app_section'
import FinancialResilience from '../components/financial_resilience/financial_resilience'
import ProofPoints from '../components/proof_points/proof_points'
import Testimonial from '../components/testimonial/testimonial'
import { assetNames } from '../utilities.js/constants'

import './base.css'
import Hero from '../components/ui/hero/hero'
import Layout from '../components/website_layout/layout'

// Prevent Gatsby SSR build errors
const isBrowser = typeof window !== 'undefined'

const Home = (props) => {
  const assets = assetNames.reduce((res, assetName) => {
    res[assetName] = props.data[assetName]?.gatsbyImageData
    return res
  }, {})

  const schemaScript = (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: `
        {
          "@context": "https://schema.org/", 
          "@type": "HowTo", 
          "name": "How to get a pay advance",
          "description": "Here are the three simple steps to get a pay advance",
          "step": [{
            "@type": "HowToStep",
            "text": "Download Wagetap by visiting the App Store or Play Store"
          },{
            "@type": "HowToStep",
            "text": "Create your account by providing your name, email address and phone number"
          },{
            "@type": "HowToStep",
            "text": "Pick the amount of money you need to withdraw up to $500"
          }]    
        }`,
      }}
    ></script>
  )

  useEffect(() => {
    if (isBrowser) {
      // Track seeing this page
      window.analytics.track('website_page_viewed', {
        page: 'home',
      })
    }
  }, [])

  return (
    <>
      <Layout
        data={props.data.contentfulHomePageContent}
        seo={props.data.contentfulSiteMetadata}
        assets={assets}
        showScrollToTop={true}
      >
        <Hero
          data={props.data.contentfulHomePageContent.mainContent}
          assets={assets}
        />
        <FinancialResilience
          data={props.data.contentfulHomePageContent.appAccessContent}
        />
        <EmployeeAppSection
          assets={assets}
          data={props.data.contentfulHomePageContent.productListContent}
        />
        <ProofPoints
          data={props.data.contentfulHomePageContent.proofPointContent}
        />
        <Testimonial
          data={props.data.contentfulHomePageContent.testimonialContent}
        />
        {schemaScript}
      </Layout>
    </>
  )
}

export default Home

export const pageQuery = graphql`
  query HomeQuery {
    contentfulHomePageContent(contentful_id: { eq: "5yDSrQs4or68bmXVL9e85K" }) {
      topNavigationBar {
        navPaths {
          ... on ContentfulDropDown {
            internal {
              type
            }
            name
            linkEntries {
              label
              url
            }
          }
          ... on ContentfulPath {
            internal {
              type
            }
            url
            label
          }
        }
        logolarge {
          file {
            url
          }
        }
      }
      mainContent {
        successMessage
        errorMessage
        rightImage {
          gatsbyImageData(width: 800)
          title
        }
        subtitle
        topBubbleText
        title
        fieldPlaceholder
        fieldButton {
          label
          url
        }
        formTitle
      }
      appAccessContent {
        title
        subtitle
        subHeading
        itemType {
          title
          subtitle
          image {
            gatsbyImageData(width: 60)
            title
          }
        }
      }
      productListContent {
        id
        imageStartsLeft
        productContentItem {
          advantages
          moreInfo {
            label
            url
          }
          subtitle
          title
          image {
            gatsbyImageData(width: 640)
            title
          }
          left
          paragraph {
            childMarkdownRemark {
              html
              rawMarkdownBody
              htmlAst
            }
          }
        }
      }
      proofPointContent {
        title
        subtitle
        item {
          title
          titleBold1 {
            childMarkdownRemark {
              html
              rawMarkdownBody
              htmlAst
            }
          }
          subtitle
          paragraph
          image {
            gatsbyImageData(width: 96)
            title
          }
          leftImage {
            gatsbyImageData(width: 80)
            title
          }
          rightImage {
            gatsbyImageData(width: 80)
            title
          }
          link {
            url
            label
          }
        }
        paragraph
      }
      testimonialContent {
        title
        item {
          rating
          title
          username
          paragraph {
            raw
          }
        }
      }
      ctaSectionContent {
        successMessage
        errorMessage
        subtitle
        title
        placeholderTitle
        placeholderFieldName
        ctaTitle
        image {
          gatsbyImageData(width: 396)
          title
        }
      }
      footerContent {
        section1Title
        section2Title
        section3Title
        title
        section1Paths {
          newTab
          label
          url
          logo {
            gatsbyImageData(width: 80)
            title
          }
        }
        section2Paths {
          newTab
          label
          url
          logo {
            gatsbyImageData(width: 80)
            title
          }
        }

        section3Paths {
          newTab
          label
          url
          logo {
            gatsbyImageData(width: 80)
            title
          }
        }
      }
    }
    googlePlayBadgeBlack: contentfulCtaButton(
      contentful_id: { eq: "q00MfpdmihX36ZSSzO2VS" }
    ) {
      buttonAlt
      buttonImage {
        gatsbyImageData(width: 235)
      }
    }
    appStoreBadgeBlack: contentfulCtaButton(
      contentful_id: { eq: "4JX8m4WjDpVydFskT6GEeu" }
    ) {
      buttonAlt
      buttonImage {
        gatsbyImageData(width: 235)
      }
    }
    googlePlayBadge: contentfulAsset(
      file: { fileName: { eq: "google-store-badge-new.png" } }
    ) {
      gatsbyImageData(width: 235)
    }
    appStoreBadge: contentfulAsset(
      file: { fileName: { eq: "app-store-badge-new.png" } }
    ) {
      gatsbyImageData(width: 235)
    }
    browserBadge: contentfulAsset(
      file: { fileName: { eq: "browser-badge.png" } }
    ) {
      gatsbyImageData(width: 235)
    }
    contentfulSiteMetadata(contentful_id: { eq: "4GzU8oQlPc2YDh3dwyPU1N" }) {
      description
      imageUrl
      siteName
      title
      twitterHandle
      siteUrl
    }
  }
`
